<template>
  <section class="contacts">
    <div class="container">
      <div class="row">
        <div
          class="col-xx-lg-4 col-xx-lg-offset-2 col-x-lg-7 col-md-6 col-xs-12"
        >
          <div class="contacts__form">
            <h2 class="contacts__form-title">Зв’язатися з нами</h2>
            <div
              class="contacts__form-field"
              :class="requiredName.isError ? 'error' : ''"
            >
              <label for="user-name">Iм'я</label>
              <input
                type="text"
                id="user-name"
                v-model="formData.userName"
                placeholder="Яна"
              />
              <div
                class="contacts__form-field-error"
                v-if="requiredName.isError"
              >
                {{ requiredName.message }}
              </div>
            </div>
            <div
              class="contacts__form-field"
              :class="requiredEmail.isError ? 'error' : ''"
            >
              <label for="user-email">E-mail</label>
              <input
                type="email"
                id="user-email"
                v-model="formData.userEmail"
                placeholder="Email.example@gmail.com"
              />
              <div
                class="contacts__form-field-error"
                :class="formData.userEmail.trim().length"
                v-if="requiredEmail.isError"
              >
                {{ requiredEmail.message }}
              </div>
            </div>
            <div
              class="contacts__form-field"
              :class="requiredMessage.isError ? 'error' : ''"
            >
              <label for="user-message">Повiдомлення</label>
              <input
                type="text"
                id="feedback-message"
                v-model="formData.userMessage"
                placeholder="Що ви хотіли спитати?"
              />
              <div
                class="contacts__form-field-error"
                v-if="requiredMessage.isError"
              >
                {{ requiredMessage.message }}
              </div>
            </div>

            <div class="contacts__form-checkbox">
              <input
                type="checkbox"
                checked
                id="contacts-privacy"
                v-model="userPrivacy"
                placeholder
              />
              <label for="contacts-privacy">
                Я надаю Адміністратору Програми право здійснювати обробку
                (включаючи збирання, зберігання, використання, видалення і т.д.)
                інформації, яку я вказав(ла) в даному повідомленні, з метою
                надання відповіді на моє запитання.
              </label>
              <div
                class="feedback__form-field-error"
                v-if="requiredPrivacy.isError"
              >
                {{ requiredPrivacy.message }}
              </div>
            </div>
            <div class="contacts__form-options">
              <input
                type="button"
                class="contacts__form-submit"
                @click="submitForm"
                value="Надіслати"
              />
            </div>
          </div>
        </div>
        <div
          class="col-xx-lg-5 col-xx-lg-offset-1 col-x-lg-5 col-md-6 col-xs-12"
        >
          <div class="contacts__info">
            <a href="tel:0 800 211 202" class="contacts__info-phone"
              >0 800 211 202</a
            >
            <div class="contacts__info-title">
              Із запитаннями щодо Програми ТерапіяПЛЮС Ви можете звертатись
              <br />до Адміністратора Програми
            </div>
            <div class="contacts__info-text">
              за телефоном гарячої лінії (щоденно з 9:00 до 21:00).
            </div>
            <div class="contacts__info-text">
              Дзвінки з усіх телефонних номерів в межах України безкоштовні.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Contacts",
  data() {
    return {
      userPrivacy: true,
      formData: {
        userName: "",
        userEmail: "",
        userMessage: ""
      },
      requiredName: {
        isError: false,
        message: "Будь ласка, заповніть це поле"
      },
      requiredEmail: {
        isError: false,
        message: "Будь ласка, введіть валідну e-mail адресу"
      },
      requiredMessage: {
        isError: false,
        message: "Будь ласка, введіть повідомлення"
      },
      requiredPrivacy: {
        isError: false,
        message: "Ви не погодились з політикою конфіденційності"
      }
    };
  },
  methods: {
    ...mapMutations(["setHotline", "setContactsSuccess", "setContactsError"]),
    submitForm() {
      if (this.userPrivacy) {
        this.requiredPrivacy.isError = false;
        // Validate Name
        if (!this.formData.userName) {
          this.requiredName.isError = true;
        } else if (!this.validateName(this.formData.userName)) {
          this.requiredName.isError = true;
        } else {
          this.requiredName.isError = false;
        }
        // Validate Email
        if (!this.formData.userEmail) {
          this.requiredEmail.isError = true;
        } else if (!this.validateEmail(this.formData.userEmail)) {
          this.requiredEmail.isError = true;
        } else {
          this.requiredEmail.isError = false;
        }
        // Validate Ьуііфпу

        if (!this.formData.userMessage) {
          this.requiredMessage.isError = true;
        } else if (!this.validateMessage(this.formData.userMessage)) {
          this.requiredMessage.isError = true;
        } else {
          this.requiredMessage.isError = false;
        }
      } else {
        this.requiredPrivacy.isError = true;
      }
      // if form valid
      if (
        this.validateName(this.formData.userName) &&
        this.validateEmail(this.formData.userEmail) &&
        this.validateMessage(this.formData.userMessage) &&
        !this.requiredPrivacy.isError
      ) {
        // var data = {
        //   name: this.formData.userName,
        //   email: this.formData.userEmail,
        //   text: this.formData.userMessage
        // };
        var fData = new FormData();
        fData.append("name", this.formData.userName);
        fData.append("email", this.formData.userEmail);
        fData.append("text", this.formData.userMessage);
        this.axios
          .post("https://medicard.com.ua/api/feedback.php", fData)
          .then(res => {
            if (res.status != "200") {
              this.setContactsError(true);
              this.setContactsSuccess(false);
            } else {
              this.setContactsError(false);
              this.setContactsSuccess(true);
            }
          });
      }
    },
    validateName(userName) {
      const reName = /^[А-Яа-яёЁЇїІіЄєҐґ]+$/;
      return reName.test(userName);
    },
    validateEmail(userEmail) {
      var reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reEmail.test(userEmail);
    },
    validateMessage(userMessage) {
      // const msg = /^[А-Яа-яёЁЇїІіЄєҐґa-zA-Z]+$/;
      return userMessage.length > 0;
    }
  }
};
</script>
