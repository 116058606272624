<template>
  <main>
    <Contacts />
    <ContactsSuccess />
    <ContactsError />
  </main>
</template>

<script>
import Contacts from "@/components/contacts/Contacts";
import ContactsSuccess from "@/components/modals/ContactsSuccess";
import ContactsError from "@/components/modals/ContactsError";

export default {
  name: "ContactsView",
  components: {
    Contacts,
    ContactsSuccess,
    ContactsError
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/views/_contacts";
</style>
